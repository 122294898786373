<template>
    <div class="inner-container">
        <!--     фильтры/кнопки     -->
        <div class="filter-container">
            <div class="left-content">
                <b-dropdown class="filter-dropdown" variant="default" ref="drop">
                    <template #button-content>
                        <span class="lc"><i class="icon icon-filter"></i>{{ getBipTitle('filter.title') }}</span>
                        <span class="rc"><i class="icon icon-keyboard"></i></span>
                    </template>
                    <div>
                        <div class="top-content">
                            <span>{{ getFilterTitle('title') }}</span>
                            <i class="icon icon-close" @click="$refs.drop.hide(true)"></i>
                        </div>
                        <!--------------- Группа критериев ------------------->
                        <div class="filter-block">
                            <b-form-group :label="getFilterTitle('criteria_group')">
                                <multiselect ref="bcCritType"
                                             v-model="selCritType"
                                             track-by="text"
                                             label="text"
                                             :placeholder="getFilterTitle('sel_criteria_group')"
                                             :options="criteriaTypeList"
                                             :searchable="true"
                                             :allow-empty="true"
                                             :show-labels="false">
                                </multiselect>
                            </b-form-group>
                        </div>
                        <!--------------- Логика расчета ------------------->
                        <div class="filter-block">
                            <b-form-group :label="getFilterTitle('calc_logic')">
                                <multiselect ref="bcCalcType"
                                             v-model="selCalcType"
                                             track-by="text"
                                             label="text"
                                             :placeholder="getFilterTitle('sel_calc_logic')"
                                             :options="calcList"
                                             :searchable="true"
                                             :allow-empty="true"
                                             :show-labels="false">
                                </multiselect>
                            </b-form-group>
                        </div>
                        <!--------------- АБП/CSI ------------------->
                        <div class="filter-block">
                            <b-form-group :label="getFilterTitle('ABP') + '/CSI'">
                                <multiselect ref="bcOper"
                                             v-model="selOper"
                                             track-by="value"
                                             label="value"
                                             :placeholder="getFilterTitle('sel_abp_csi')"
                                             :options="operList"
                                             :searchable="true"
                                             :allow-empty="true"
                                             :show-labels="false">
                                </multiselect>
                            </b-form-group>
                        </div>
                        <!--------------- Расчетное/вводимое ------------------->
                        <div class="filter-block">
                            <b-form-group :label="getFilterTitle('calc_input')">
                                <multiselect ref="bcSource"
                                             v-model="selSourceType"
                                             track-by="text"
                                             label="text"
                                             :placeholder="getFilterTitle('sel_calc_input')"
                                             :options="sourceList"
                                             :searchable="true"
                                             :allow-empty="true"
                                             :show-labels="false">
                                </multiselect>
                            </b-form-group>
                        </div>
                        <!--------------- Показать закрытые ------------------->
                        <div class="filter-block">
                            <b-form-group class="medium no-label" :label="getBipTitle('btn.show_closed')">
                                <b-form-checkbox switch
                                                 v-model="isClosed">
                                </b-form-checkbox>
                            </b-form-group>
                        </div>
                    </div>
                    <br>
                    <b-button variant="secondary" @click="resetFilter">{{ getBipTitle('btn.reset') }}</b-button>
                </b-dropdown>
            </div>
            <div class="right-content">
                <div class="filter-actions" v-if="host">
                    <b-button variant="primary" @click="addItem">
                        <i class="icon icon-plus-circle"></i>{{ getBipTitle('btn.add') }}
                    </b-button>
                    <b-button variant="success" @click="save">{{ getBipTitle('btn.save') }}</b-button>
                </div>
            </div>
        </div>
        <!--        хлебные крошки-->
        <div class="filter-breadcrumb">
            <span class="item-breadcrumb" v-if="!openFilterWindow && selCritType"
                  @click="openFilterByRef('bcCritType')">
                {{ selCritType.text }}
            </span>
            <span class="item-breadcrumb" v-if="!openFilterWindow && selCalcType"
                  @click="openFilterByRef('bcCalcType')">
                {{ selCalcType.text }}
            </span>
            <span class="item-breadcrumb" v-if="!openFilterWindow && selOper"
                  @click="openFilterByRef('bcOper')">
                {{ selOper.value }}
            </span>
            <span class="item-breadcrumb" v-if="!openFilterWindow && selSourceType"
                  @click="openFilterByRef('bcSource')">
                {{ selSourceType.text }}
            </span>
        </div>
        <div class="table-container">
            <b-table
                ref="selectableTable"
                :fields="tableFields"
                :items="showCifList"
                responsive="true"
                head-variant="light"
                bordered
                sticky-header
                table-class="vertical-align-top"
                no-border-collapse>
                <template #cell(code)="data">
                    <div :class="{ 'error': data.item.code === null || data.item.code.toString().length === 0 }">
                        <b-form-input class="text-right"
                                      :disabled="data.item.id > 0"
                                      v-model="data.item.code"
                                      @change="setCurCriteria(data.item)">
                        </b-form-input>
                        <template v-if="data.item.code === null || data.item.code.toString().length === 0">
                            <i class="icon icon-danger"></i>
                            <div class="pop-up">
                                <p class="red-text">{{ getSmsTitle('attention') }}</p>
                                <p>{{ getSmsTitle('field_required') }}</p>
                            </div>
                        </template>
                    </div>
                </template>
                <template #cell(name_ru)="data">
                    <b-form-group>
                        <div :class="{ 'error': data.item.name_ru === null || data.item.name_ru.toString().length === 0 }">
                            <b-form-textarea
                                rows="3"
                                max-rows="5"
                                class="w-250px"
                                v-model="data.item.name_ru"
                                @change="setCurCriteria(data.item)">
                            </b-form-textarea>
                            <template v-if="data.item.name_ru === null || data.item.name_ru.toString().length === 0">
                                <i class="icon icon-danger"></i>
                                <div class="pop-up">
                                    <p class="red-text">{{ getSmsTitle('attention') }}</p>
                                    <p>{{ getSmsTitle('field_required') }}</p>
                                </div>
                            </template>
                        </div>
                    </b-form-group>
                </template>
                <template #cell(name_kz)="data">
                    <b-form-group>
                        <div :class="{ 'error': data.item.name_kz === null || data.item.name_kz.toString().length === 0 }">
                            <b-form-textarea
                                rows="3"
                                max-rows="5"
                                class="w-250px"
                                v-model="data.item.name_kz"
                                @change="setCurCriteria(data.item)">
                            </b-form-textarea>
                            <template v-if="data.item.name_kz === null || data.item.name_kz.toString().length === 0">
                                <i class="icon icon-danger"></i>
                                <div class="pop-up">
                                    <p class="red-text">{{ getSmsTitle('attention') }}</p>
                                    <p>{{ getSmsTitle('field_required') }}</p>
                                </div>
                            </template>
                        </div>
                    </b-form-group>
                </template>
                <template #cell(criteria_type)="data">
                    <b-form-group :class="{ 'with-warning': data.item.criteria_type === null }">
                        <b-form-select v-model="data.item.criteria_type"
                                       :options="criteriaTypeList"
                                       value-field="value"
                                       text-field="text"
                                       @change="setCurCriteria(data.item)">
                        </b-form-select>
                        <template v-if="data.item.criteria_type === null">
                            <div class="form-tooltip">
                                <i class="icon icon-danger"></i>
                                <div class="form-tooltip-content">
                                    <p class="red mb-2">{{ getSmsTitle('attention') }}
                                        <br>{{ getSmsTitle('field_required') }}</p>
                                </div>
                            </div>
                        </template>
                    </b-form-group>
                </template>
                <template #cell(unit_item)="data">
                    <b-form-group :class="{ 'with-warning': data.item.unit_item === null }">
                        <multiselect
                            class="units_select"
                            v-model="data.item.unit_item"
                            track-by="national_symbol"
                            label="national_symbol"
                            placeholder="Выбрать ед.изм"
                            :options="dictUnit"
                            :searchable="true"
                            :allow-empty="false"
                            @input="setCurCriteria(data.item)">
                            <template>
                                <strong>{{ data.item.unit_item }}</strong>
                            </template>
                        </multiselect>
                        <template v-if="data.item.unit_item === null">
                            <div class="form-tooltip">
                                <i class="icon icon-danger"></i>
                                <div class="form-tooltip-content">
                                    <p class="red mb-2">{{ getSmsTitle('attention') }}
                                        <br>{{ getSmsTitle('field_required') }}</p>
                                </div>
                            </div>
                        </template>
                    </b-form-group>

                </template>
                <template #cell(data_type)="data">
                    <b-form-group :class="{ 'with-warning': data.item.data_type === null}">
                        <b-form-select v-model="data.item.data_type"
                                       :options="dataTypeList"
                                       value-field="value"
                                       text-field="text"
                                       @change="setCurCriteria(data.item)">
                        </b-form-select>
                        <template v-if="data.item.data_type === null">
                            <div class="form-tooltip">
                                <i class="icon icon-danger"></i>
                                <div class="form-tooltip-content">
                                    <p class="red mb-2">{{ getSmsTitle('attention') }}
                                        <br>{{ getSmsTitle('field_required') }}</p>
                                </div>
                            </div>
                        </template>
                    </b-form-group>
                </template>
                <template #cell(source_type)="data">
                    <b-form-group :class="{ 'with-warning': data.item.source_type === null }">
                        <b-form-select
                            v-model="data.item.source_type"
                            :options="sourceList"
                            value-field="value"
                            text-field="text"
                            @change="setCurCriteria(data.item)">
                        </b-form-select>
                        <template v-if="data.item.source_type === null">
                            <div class="form-tooltip">
                                <i class="icon icon-danger"></i>
                                <div class="form-tooltip-content">
                                    <p class="red mb-2">{{ getSmsTitle('attention') }}
                                        <br>{{ getSmsTitle('field_required') }}</p>
                                </div>
                            </div>
                        </template>
                    </b-form-group>
                </template>
                <template #cell(calc_type)="data">
                    <b-form-group
                        :class="{ 'with-warning': data.item.criteria_type !== '4' && data.item.calc_type === null }">
                        <b-form-select v-model="data.item.calc_type"
                                       :options="calcList"
                                       value-field="value"
                                       text-field="text"
                                       @change="setCurCriteria(data.item)">
                            <template #first>
                                <b-form-select-option :value="null"></b-form-select-option>
                            </template>
                        </b-form-select>
                        <template v-if="data.item.criteria_type !== '4' && data.item.calc_type === null">
                            <div class="form-tooltip">
                                <i class="icon icon-danger"></i>
                                <div class="form-tooltip-content">
                                    <p class="red mb-2">{{ getSmsTitle('attention') }}
                                        <br>{{ getSmsTitle('field_required') }}</p>
                                </div>
                            </div>
                        </template>
                    </b-form-group>
                </template>
                <template #cell(operator)="data">
                    <b-form-group
                        :class="{ 'with-warning': data.item.source_type === 'W' && data.item.operator === null }">
                        <b-form-select :disabled="data.item.source_type !== 'W'"
                                       v-model="data.item.operator"
                                       :options="operList"
                                       value-field="value"
                                       text-field="value"
                                       @change="setCurCriteria(data.item)">
                        </b-form-select>
                        <template v-if="data.item.source_type === 'W' && data.item.operator === null">
                            <div class="form-tooltip">
                                <i class="icon icon-danger"></i>
                                <div class="form-tooltip-content">
                                    <p class="red mb-2">{{ getSmsTitle('attention') }}
                                        <br>{{ getSmsTitle('field_required') }}</p>
                                </div>
                            </div>
                        </template>
                    </b-form-group>
                </template>
                <template #cell(begin_date)="data">
                    <b-form-group :class="{ 'with-warning': data.item.begin_date === null }">
                        <date-picker
                            v-model="data.item.begin_date"
                            @input="setCurCriteria(data.item)"
                            format="DD.MM.YYYY"
                            placeholder="__-__-____"
                        >
                        </date-picker>
                        <template v-if="data.item.begin_date === null">
                            <div class="form-tooltip">
                                <i class="icon icon-danger"></i>
                                <div class="form-tooltip-content">
                                    <p class="red mb-2">{{ getSmsTitle('attention') }}
                                        <br>{{ getSmsTitle('field_required') }}</p>
                                </div>
                            </div>
                        </template>
                    </b-form-group>
                </template>
                <template #cell(end_date)="data">
                    <date-picker
                        v-model="data.item.end_date"
                        @input="setCurCriteria(data.item)"
                        format="DD.MM.YYYY"
                        placeholder="__-__-____"
                    ></date-picker>
                </template>
                <template #cell(more)="data">
                    <span v-if="host" class="remove"
                          @click="deleteItem(data.item, data.index)">
                        <i class="icon icon-clear"></i>
                    </span>
                </template>
            </b-table>
        </div>
        <loading
            :active="loading"
            is-full-screen
            spinner="bar-fade-scale"
            color="#6495ED"
        />
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import DatePicker from 'vue2-datepicker';
import store from "@/services/store";
import VueElementLoading from "vue-element-loading";
import {
    findItem,
    getBipTitle, getCardTitle,
    getFilterTitle,
    getSmsTitle, getTFieldTitle,
    makeToast,
    sortByField
} from "@/modules/budget/bip/bip-types";

export default {
    name: 'bip-test',

    components: {DatePicker, 'loading': VueElementLoading},

    props: {
        host: undefined,

        bipCifList: {
            type: Array,
            default: () => []
        },
        dictUnit: {
            type: Array,
            default: () => []
        }
    },

    data() {
        return {
            selCritType: null,

            selCalcType: null,

            sourceList: [
                {value: 'R', text: 'Р'},
                {value: 'W', text: 'В'}
            ],
            selSourceType: null,

            selOper: null,

            isClosed: false,
            curId: 0,
            openFilterWindow: false,
            loading: false
        }
    },

    async mounted() {
        if (this.bipCifList.length > 0) {
            console.log(new Date().toISOString(), 'test bipCifList = ', this.bipCifList.length);
            await this.loadDict();
        }
        if (this.dictUnit.length > 0) {
            console.log(new Date().toISOString(), 'test dictUnit = ', this.dictUnit.length);
            this.dictUnit.sort(sortByField('code'));
        }
    },

    computed: {
        userUiid() {
            return store.state.user.sub;
        },

        tableFields() {
            return [
                {
                    key: 'id',
                    label: 'id',
                    sortable: true
                },
                {
                    key: 'code',
                    label: getTFieldTitle(this, 'code'),
                    sortable: true
                },
                {
                    key: 'name_ru',
                    label: getCardTitle(this, 'sector_1', 'name_ru'),
                    sortable: true
                },
                {
                    key: 'name_kz',
                    label: getCardTitle(this, 'sector_1', 'name_kk'),
                    sortable: true,
                    variant: 'warning'
                },
                {
                    key: 'criteria_type',
                    label: getBipTitle(this, 'criterias'),
                    sortable: true
                },
                {
                    key: 'unit_item',
                    label: getTFieldTitle(this, 'unit')
                },
                {
                    key: 'data_type',
                    label: getFilterTitle(this, 'data_type')
                },
                {
                    key: 'source_type',
                    label: getTFieldTitle(this, 'calc_P_input_V')
                },
                {
                    key: 'calc_type',
                    label: getFilterTitle(this, 'calc_logic')
                },
                {
                    key: 'operator',
                    label: getFilterTitle(this, 'ABP') + '/CSI'
                },
                {
                    key: 'begin_date',
                    label: getTFieldTitle(this, 'start_date')
                },
                {
                    key: 'end_date',
                    label: getTFieldTitle(this, 'exp_date')
                },
                {
                    key: 'more',
                    label: ''
                }
            ]
        },

        criteriaTypeList() {
            return [
                {value: '1', text: getBipTitle(this, 'dict.budget')},
                {value: '2', text: getBipTitle(this, 'dict.impact')},
                {value: '3', text: getBipTitle(this, 'dict.need')},
                {value: '4', text: getBipTitle(this, 'dict.additional')}
            ]
        },

        calcList() {
            return [
                {value: '3VL', text: '3VL – ' + getBipTitle(this, 'dict.three_digit')},
                {value: 'B', text: 'B – ' + getBipTitle(this, 'dict.binary')},
                {value: 'E', text: 'E – ' + getBipTitle(this, 'dict.specific')},
                {value: 'E*', text: 'E* - ' + getBipTitle(this, 'dict.specific') + getBipTitle(this, 'dict.validity_period_GE')},
                {value: 'N', text: 'N – ' + getBipTitle(this, 'dict.unidirectional')},
                {value: 'R', text: 'R - ' + getBipTitle(this, 'dict.multidirectional')}
            ]
        },

        dataTypeList() {
            return [
            {value: 'number', text: getBipTitle(this, 'dict.numeric')},
            {value: 'boolean', text: getBipTitle(this, 'dict.logical_yn')},
            {value: 'int', text: getBipTitle(this, 'dict.type_with_vals')}
            ]
        },

        operList() {
                return [
                {value: getFilterTitle(this, 'ABP')},
                {value: 'CSI'}
            ]
        },

        showCifList() {
            const now_year = new Date().getFullYear();

            let result = this.bipCifList.filter(row => !row.is_deleted);

            if (!this.isClosed) {
                result = result.filter(row => row.end_date === null
                    || (new Date(row.end_date).getFullYear() >= now_year));
            }

            if (this.selCritType) {
                console.log('selCritType', this.selCritType);
                result = result.filter(row => row.criteria_type === this.selCritType.value);
            }

            if (this.selCalcType) {
                result = result.filter(row => row.calc_type === this.selCalcType.value);
            }

            if (this.selSourceType) {
                result = result.filter(row => row.source_type === this.selSourceType.value);
            }

            if (this.selOper) {
                result = result.filter(row => row.operator === this.selOper.value);
            }
            return result;
        }
    },

    methods: {
        addItem() {
            const item = {
                id: this.curId--,
                code: null,
                name_ru: null,
                name_kz: null,
                criteria_type: null,
                unit_item: null,
                unit: null,
                data_type: null,
                source_type: '',
                calc_type: null,
                operator: null,
                begin_date: null,
                end_date: null,
                is_deleted: false,
                edit: true,
                more: false
            }
            this.bipCifList.unshift(item);
        },

        async checkContent(code) {
            const response = await fetch('/api-py/get-links-by-criteria/' + code);
            const result = await response.json();
            return result;
        },

        checkDates() {
            for (const row of this.bipCifList) {
                if (row.edit) {
                    if (row.end_date !== null) {
                        const bd = new Date(row.begin_date);
                        const ed = new Date(row.end_date);
                        if (bd > ed) {
                            return false;
                        }
                    }
                }
            }
            return true;
        },

        checkRequired() {
            for (const item of this.bipCifList) {
                if (item.edit) {
                    if (item.criteria_type !== null && item.criteria_type !== '4') {
                        if (item.code === null
                            || item.name_ru === null
                            || item.criteria_type === null
                            || item.unit === null
                            || item.data_type === null
                            || item.source_type === null
                            || item.source_type.toString().length === 0
                            || item.calc_type === null
                            || item.begin_date === null) {
                            return false;
                        }
                        if (item.source_type === 'W') {
                            if (item.operator === null) {
                                return false;
                            }
                        }
                    }
                }
            }
            return true;
        },

        async deleteItem(item, index) {
            let res = false;
            if (item.id > 0) {
                res = await this.checkContent(item.code);
            }
            this.$bvModal.msgBoxConfirm(
                getSmsTitle(this, 'confirm_del') + '\"' + item.name_ru + '\"',
                {
                    title: getSmsTitle(this, 'confirmation'),
                    size: 'lg',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: getBipTitle(this, 'btn.delete'),
                    cancelTitle: getBipTitle(this, 'btn.cancel'),
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                }).then(value => {
                    if (value) {
                        if (item.id <= 0) {
                            this.bipCifList.splice(this.bipCifList.findIndex(v => v.id === item.id), 1);
                            // this.loadDatas();
                            makeToast(this, 'success', getSmsTitle(this, 'message'),
                                getSmsTitle(this, 'post_deleted'));
                        } else {
                            if (res) {
                                this.$bvModal.msgBoxConfirm(
                                    getSmsTitle(this, 'criterion_not_deleted_it_used_in_link'),
                                    {
                                        title: getSmsTitle(this, 'confirmation'),
                                        size: 'lg',
                                        buttonSize: 'sm',
                                        okVariant: 'danger',
                                        okTitle: getBipTitle(this, 'btn.correct'),
                                        cancelTitle: getBipTitle(this, 'btn.cancel'),
                                        footerClass: 'p-2',
                                        hideHeaderClose: false,
                                        centered: true
                                    }).then(() => {
                                        return;
                                    }).catch(error => {
                                        makeToast(this, 'danger', getSmsTitle(this, 'error_delete'),
                                            error.toString());
                                    });
                            } else {
                                this.delete(item, index);
                            }
                        }
                    }
                }).catch(error => {
                    makeToast(this, 'danger', getSmsTitle(this, 'error_delete'), error.toString());
                });
        },

        async delete(item, index) {
            this.$set(item, 'table', 'bip_cif_list')
            const response = await fetch('/api-py/delete-item', {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(item)
            });
            const result = await response.json();
            if (result.result === 'success') {
                await this.loadDict();
                makeToast(this, 'success', getSmsTitle(this, 'message'),
                    getSmsTitle(this, 'post_deleted'));
            }
        },

        keyup13: function (event) {
            event.preventDefault();
            // Isolate the node that we're after
            const currentNode = event.target;
            // find all tab-able elements
            const allElements = document.querySelectorAll('input'); // area, object, select, [contenteditable]
            // Find the current tab index.
            const currentIndex = [...allElements].findIndex(el => currentNode.isEqualNode(el));
            // focus the following element
            const targetIndex = (currentIndex + 1) % allElements.length;
            if (targetIndex < allElements.length) {
                allElements[targetIndex].select();
            }
        }, // enter работает как tab

        async loadDict() {
            this.loading = true;
            this.bipCifList.sort(sortByField('id'));

            for (const row of this.bipCifList) {
                this.$set(row, 'more', false);
                this.$set(row, 'edit', false);
                this.$set(row, 'unit_item', findItem(row.unit, 'national_symbol', this.dictUnit));

                const dt = new Date(row.begin_date);
                this.$set(row, 'begin_date', dt);
                this.$set(row, 'begin_year', dt.getFullYear());
                if (row.end_date !== null) {
                    const dt = new Date(row.end_date);
                    this.$set(row, 'end_date', dt);
                    this.$set(row, 'end_year', dt.getFullYear());
                } else {
                    this.$set(row, 'end_year', null);
                }
            }
            this.loading = false;
        },

        message(sms) {
            this.$bvModal.msgBoxConfirm(
                sms, {
                    title: getSmsTitle(this, 'attention'),
                    size: 'md',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: getBipTitle(this, 'btn.correct'),
                    cancelTitle: getBipTitle(this, 'btn.cancel'),
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true,
                    modalClass: 'del-item-modal-script'
                }).then(() => {
                    return;
                }).catch(error => {
                    makeToast(this, 'danger', getSmsTitle(this, 'error_control'), error.toString());
                });
        },

        openFilterByRef(refName) {
            const drop = this.$refs.drop;
            drop.show(true);
            const refItem = this.$refs[refName];
            setTimeout(() => refItem.$el.focus(), 100);
        },

        resetFilter() {
            this.selCritType = null;
            this.selSourceType = null;
            this.selOper = null;
            this.selCalcType = null;
            this.isClosed = false;
        },

        async save() {
            const checkRequired = this.checkRequired();
            if (!checkRequired) {
                this.message(getSmsTitle(this, 'data_not_saved')
                    + getSmsTitle(this, 'required_fields_not_met'));
                return;
            }
            const checkDates = this.checkDates();
            if (!checkDates) {
                this.message(getSmsTitle(this, 'data_not_saved')
                    + getSmsTitle(this, 'start_date_must_less_end_date'));
                return;
            }

            if (checkDates && checkRequired) {
                const items = [];
                for (const row of this.bipCifList) {
                    if (row.edit) {
                        this.$set(row, 'user_name', this.userUiid);
                        items.push(row);
                    }
                }
                console.log('items saving', items);
                const response = await fetch('/api-py/save-bip-cif', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(items)
                });
                const result = await response.json();
                if (result.result === 'success') {
                    await this.loadDict();
                    makeToast(this, 'success', getSmsTitle(this, 'message'),
                        getSmsTitle(this, 'datas_saved'));
                }
            }
        },

        setCurCriteria(item) {
            this.$set(item, 'edit', true);
            if (item.begin_date !== null) {
                const dt = new Date(item.begin_date);
                dt.setMonth(0, 1);
                dt.setHours(6, 0, 0, 0);
                this.$set(item, 'begin_date', dt);
                this.$set(item, 'begin_year', dt.getFullYear());
            }
            if (item.end_date !== null) {
                const dt = new Date(item.end_date);
                dt.setMonth(11, 31);
                dt.setHours(6, 0, 0, 0);
                this.$set(item, 'end_date', dt);
                this.$set(item, 'end_year', dt.getFullYear());
            }

            if (item.source_type !== 'W') {
                item.operator = null;
            }
            if (item.criteria_type === '4') {
                item.calc_type = null;
            }
            this.$set(item, 'unit', item.unit_item !== null ? item.unit_item.national_symbol : null);
        },

        getBipTitle(field) {
            return getBipTitle(this, field);
        },

        getFilterTitle(field) {
            return getFilterTitle(this, field);
        },

        getSmsTitle(field) {
            return getSmsTitle(this, field);
        }
    }
};
</script>

<style>
.is-hidden {
    display: none !important;
}
</style>
<style scoped>
.item-tool {
    margin-top: 5px;
}
</style>
<template>
    <div class="inner-container">
        <b-tabs v-model="tabIndex" @input="loadTab">
            <b-tab active :title="getBipTitle('criterias')">
                <bip-test :host="host"
                          :bip-cif-list="bipCifList"
                          :dict-unit="dictUnit">
                </bip-test>
            </b-tab>
            <b-tab :lazy="!tabs.includes(tabIndex)" :title="getBipTitle('ligament_types')">
                <template v-if="tabs.includes(tabIndex)">
                    <bip-link :host="host"
                              :bip-cif-list="bipCifList"
                              :object-list="objectList"
                              :direction-list="directionList">
                    </bip-link>
                </template>
            </b-tab>
            <b-tab :title="getBipTitle('values')">
                <bip-values :host="host"
                            :bip-cif-list="bipCifList"
                            :object-list="objectList"
                            :direction-list="directionList"
                            :dict-unit="dictUnit"
                            :bip-link-types="bipLinkTypes"
                            :bip-link-criterias="bipLinkCriterias"
                ></bip-values>
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
import 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import BipTest from '@/modules/budget/bip/bip-test.vue';
import BipLink from '@/modules/budget/bip/bip-link.vue';
import BipValues from '@/modules/budget/bip/bip-values.vue';
import {getBipTitle} from "@/modules/budget/bip/bip-types";

export default {
    name: 'bip-model',
    components: { BipTest, BipLink, BipValues },
    props: {
        host: undefined,

        bipCifList: {
            type: Array,
            default: () => []
        },
        dictUnit: {
            type: Array,
            default: () => []
        },
        objectList: {
            type: Array,
            default: () => []
        },
        directionList: {
            type: Array,
            default: () => []
        },
        bipLinkTypes: {
            type: Array,
            default: () => []
        },
        bipLinkCriterias: {
            type: Array,
            default: () => []
        }
    },

    data() {
        return {
            tabIndex: 0,
            tabs: []
        }
    },

    methods: {
        loadTab() {
            if (!this.tabs.includes(this.tabIndex)) {
                this.tabs.push(this.tabIndex);
            }
        }, // загрузка при открытии вкладки

        getBipTitle(field) {
            return getBipTitle(this, field);
        }
    }
}
</script>